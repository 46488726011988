<button *ngIf="type === 'flat'" type="button" mat-flat-button [color]="color" (click)="doClick()" [disabled]="isLoading || isDisabled" [tabindex]="tabindex" [class]="class">
  <ng-template [ngTemplateOutlet]="label"></ng-template>
</button>
<button *ngIf="type === 'text'" type="button" mat-button [color]="color" (click)="doClick()" [disabled]="isLoading || isDisabled" [tabindex]="tabindex" [class]="class">
  <ng-template [ngTemplateOutlet]="label"></ng-template>
</button>
<button
  *ngIf="type === 'stroked'"
  type="button"
  mat-stroked-button
  [color]="color"
  (click)="doClick()"
  [disabled]="isLoading || isDisabled"
  [tabindex]="tabindex"
  [class]="class">

  <ng-template [ngTemplateOutlet]="label"></ng-template>
</button>

<ng-template #label>
  <div class="flex flex-row items-center">
    <mat-spinner diameter="20" *ngIf="isLoading" class="mr-2"></mat-spinner>
    <span><ng-content></ng-content></span>
  </div>
</ng-template>