import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { CategoryType, Dictionary, ViewValue } from 'src/app/shared/models/global';
import { MusicCategory } from '../../models/music.model';
import { ResidentPreference, ResidentPreferenceMusic, ResidentPreferenceProfile } from 'src/app/modules/resident-profile/models/profile-form.model';

export interface Resident {
  id: string;
  facilityId: string;
  preferenceId: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
  roomNumber: string;
  program?: string[];
}


@Injectable({
  providedIn: 'root'
})
export class ResidentService {

  constructor(
    private apiService: ApiService,
    private appState: AppStateService,
  ) { }

  getCategories(params: {
    slug: string,
    type: CategoryType,
  }): Observable<ViewValue[]> {
    const data: Dictionary<any> = {
      type: params.type,
      slug: params.slug
    };

    const statement = `
      query getCategories($input: CategoryInput) {
        getCategories(input: $input) {
          category
          slug
          sort
        }
      }
    `;
    return this.apiService
      .graphql<MusicCategory[]>({ statement, variables: { input: data }, type: 'getCategories'})
      .pipe(
        map(result => result.map(val => ({ viewValue: val.category, value: val.slug })).sort((a, b) => a.viewValue < b.viewValue ? -1 : 1))
      )
  }

  getResident(input: { facilityId: string, residentId: string }): Observable<Resident> {
    const statement = `
      query getResident($input: GetResidentInput!) {
        getResident(input: $input) {
          id
          firstName
          lastName
          preferredName
          roomNumber
          preferenceId
          program
        }
      }
    `;
    return this.apiService
      .graphql<Resident>({ statement, variables: { input }, type: 'getResident' })
      .pipe(
        tap(resident => {
          this.appState.setState('currentResident', resident);
        })
      )
  }


  getArtists(): Observable<ViewValue[]> {
    const statement = `
      query getArtists {
        getArtists {
          viewValue
          value
        }
      }
    `;
    return this.apiService
      .graphql<ViewValue[]>({ statement, variables: {}, type: 'getArtists'})
      .pipe(
        map(artists => artists.sort((a, b) => a.viewValue > b.viewValue ? 1 : -1))
      )

  }

  /**
   * This function is used to update all type of resident preferences and favourites which is all part of the forms object (optionally)
   */
  updateResidentPreference(input: {
    facilityId: string;
    preferenceId: string;
    residentId: string;
    forms: {
      music?: ResidentPreferenceMusic,
      profile?: ResidentPreferenceProfile,
    }
  }) {
    const statement = `
      mutation updateResidentPreference($input: UpdateResidentPreferenceInput!) {
        updateResidentPreference(input: $input)
      }
    `;
    return this.apiService
      .graphql<ResidentPreference>({ statement, variables: { input }, type: 'updateResidentPreference' })
      .pipe(
        tap(result => {
          if (result) {
            // update Current Resident data
            if (input.forms.profile) {
              this.appState.setState('currentResident', {
                ...this.appState.get<Resident>('currentResident'),
                firstName: input.forms.profile.firstName,
                lastName: input.forms.profile.lastName,
                preferredName: input.forms.profile.preferredName,
                roomNumber: input.forms.profile.roomNumber,
              });
            }
            this.appState.setState('currentResidentPreferences', {
              ...this.appState.get<ResidentPreference>('currentResidentPreferences'),
              ...input.forms,
            });
          }
        })
      );
  }

  getResidentPreference(preferenceId: string): Observable<ResidentPreference> {
    const input = {
      preferenceId,
    }
    const statement = `
      query getResidentPreference($input: GetPreferencInput!) {
        getResidentPreference(input: $input) {
          profile {
            gender
            dob
            religion
            practiceReligion
            primaryLanguage
            countryOfBirth
            suggestedReligion
          }
          music {
            genre
            favouriteArtists
            suggestedArtist
            memories
          }
          occupations {
            industry
            suggestedOccupations
            memories
          }
          interests {
            interests
            suggestedInterests
            memories
          }
          pets {
            pets
            suggestedPets
            memories
          }
          sports {
            sports
            suggestedSports
            memories
          }
          notes {
            notes
          }
        }
      }
    `;
    return this.apiService
      .graphql<ResidentPreference>({ statement, variables: { input }, type: 'getResidentPreference' })
      .pipe(
        tap(data => {
          this.appState.setState('currentResidentPreferences', data);
        })
      )
  }
}
