import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SlackService {

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) { }

  sendMessage(input: { message: string, channel: string }): Observable<boolean> {
    const statement = `
      query notify($input: NotifyInput!) {
        notify(input: $input)
      }
    `;
    return this.authService.isLoggedIn()
    .pipe(
      switchMap(authState => {
        return this.apiService
          .graphql<boolean>({ statement, variables:{ input }, type: 'notify', iam: !authState.isLoggedIn})
      }))
  }
}
